document.addEventListener("DOMContentLoaded", function () {

const menu = function() {

    const btn = document.querySelector('.main__btn');
    const formWrapp = document.querySelector('.form_wrapper');
    const main = document.querySelector('.main');

    btn.addEventListener('click', function() {
        formWrapp.style.display = 'block';
        main.classList.add('active');
        document.body.style.overflow = 'hidden';
    });

}

menu();

});